<template>
  <div class="cert" v-loading="pageLoading">
    <!-- <detail-video></detail-video> -->
    
    <div class="passAll" v-if="isPassAll && baseInfo.is_cert && activeTab == 6">
      <div>
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/passAllClose.png"
          class="close"
          @click="isPassAll = false"
        />
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinPassAll.png"
        />
      </div>
    </div>
   
    
    <div class="position">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{ path: './detail2?activeTab=2&id=' + id }"
          >{{ baseInfo ? baseInfo.name : '' }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          baseInfo.name || "课程播放"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="left">
        <player :currentInfo="currentInfo"></player>
        <p class="lessonTitle">{{ baseInfo.name }}</p>
        <div
          class="type"
          v-if="typeList.length > 1"
          :class="{ typeMore: typeList.length > 2, typeLess: typeList.length <= 2 }"
        >
          <div
            class="type-item"
            @click="getTag(item.id)"
            v-for="item in typeList"
            :key="item.id"
            :class="{ active: item.id == activeTab }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="detailContent">
          <!-- 课程介绍 -->
          <!-- <div class="html" v-html="baseInfo.content" v-show="activeTab == 1"></div> -->
          <div class="html" v-show="activeTab == 1">
            <img :src="item" v-for="item in baseInfo.content.split(',')"/>
          </div>
          <!-- 视频列表 -->
          <div class="lesson-list" v-show="activeTab == 2">
            <lessonList
              @toPlay="toPlay2"
              :currentId = "currentInfo.id"
              :down_list="down_list"
              :list="lessonList"
              :discipline_id = "baseInfo.discipline_id"
              :isBuy="baseInfo.is_buy"
              :name="baseInfo.name"
            ></lessonList>
          </div>
          <!-- 课程讲义 -->
          <div class="coverImg" v-show="activeTab == 3">
            <img :src="item.cover" v-for="(item, index) in handouts" :key="index" />
          </div>
          <!-- 思维导图 -->
          <div class="coverImg" v-show="activeTab == 4">
            <img :src="item.cover" v-for="(item, index) in map" :key="index" />
          </div>
          <!-- 纸质书籍 -->
          <div class="coverImg" v-show="activeTab == 7">
            <div v-for="(item, index) in paper_book" :key="index">
              <img :src="item.img_url" />
              <p class="btn" @click="jumpBook(item)">立即购买</p>
            </div>
          </div>
          <!-- 示范课程 -->
          <div class="lesson-list" v-show="activeTab == 5">
            <exampleList :list="demonstrate" :isBuy="baseInfo.is_buy"></exampleList>
          </div>
      
          <div class="tab-content" v-show="activeTab == 6">
            <p class="examinTitle" v-if="examinListCe.length">理论课程模拟试题</p>
            <detailExaminWH
              type=0
              :testInfo="{...item,test_message:baseInfo.test_message}"
              v-for="item in examinListCe"
              :key="item.id"
            ></detailExaminWH>
            <p class="examinTitle" v-if="examinList.length">理论课程正式试题</p>
            <detailExaminWH
              type=1
              :testInfo="{...item,test_message:baseInfo.test_message}"
              v-for="item in examinList"
              :key="item.id"
            ></detailExaminWH>
            <p class="examinTitle" style="padding-top: 20px" v-if="examinListShiCe.length">实操课程模拟试题</p>
            <div class="tab-content" v-show="activeTab == 6" v-if="examinListShiCe.length">
              <detailExaminWH
                type=0
                :testInfo="{...item,discipline_id:baseInfo.discipline_id,test_message:baseInfo.test_message}"
                v-for="item in examinListShiCe"
                :key="item.id"
              ></detailExaminWH>
            </div>
            <p class="examinTitle" style="padding-top: 20px" v-if="examinListShi.length">实操课程正式试题</p>
            <div class="tab-content" v-show="activeTab == 6" v-if="examinListShi.length">
              <detailExaminWH
                type=1
                :testInfo="{...item,discipline_id:baseInfo.discipline_id,test_message:baseInfo.test_message}"
                v-for="item in examinListShi"
                :key="item.id"
              ></detailExaminWH>
            </div>
          </div>
          <!-- 证书 -->
          <div class="tab-content" v-show="activeTab == 8">
            <cert-save :lessonNm="baseInfo.name" :userName="userName" ref="cert"></cert-save>
          </div>
        </div>
      </div>

      <div class="right detailRight">
        <div class="rightTab">
           <p v-for="item in rightTab" :key="item.id" @click="rightIndex = item.id" :class="{active:item.id == rightIndex}">{{ item.name }}</p>
        </div>
        <div class="rightContent" v-show="rightIndex == 1">
          <div v-for="item in lessonList" :key="item.id" class="rightLesson">
              <div class="rightName"  v-if="item.lesson && item.lesson.length">
                <p>{{ item.name }}</p>
              </div>
              <div class="rightName" v-else  @click="toPlay(1,item)"  :class="{rightCurrent:currentInfo.id == item.id}">
                <img :src="currentInfo.id == item.id ? rightPlay : rightPlay2"/><p>{{ item.name }}</p>
              </div>
              <div v-for="(v, i) in item.lesson" :key="i">
                <div class="rightName2"  v-if="v.speak && v.speak.length">
                  <p>{{ v.name }}</p>
                </div>
                <div class="rightName2" v-else :class="{rightCurrent:currentInfo.id == v.id}"  @click="toPlay(2, v)" >
                  <img :src="currentInfo.id == v.id ? rightPlay : rightPlay2"/><p>{{ v.name }}</p>
                </div>
                <div
                  class="rightName3"
                  :class="{rightCurrent:currentInfo.id == vv.id}"
                  v-for="(vv, ii) in v.speak"
                  :key="ii"
                  @click="toPlay(3,vv)"
                >
                <img :src="currentInfo.id == vv.id ? rightPlay : rightPlay2"/>
                  <p>{{ vv.name }}</p>
                </div>
              </div>
          </div>
        </div>
        <div class="rightContent" v-show="rightIndex == 2">
          <div v-html="currentInfo.handouts"></div>
        </div>
      </div>
      <!-- <right :list="list" :books="books"></right> -->
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import certSave from "./CertSave.vue";
import player from "./Player.vue";
import lessonList from "@/components/DetailLessonList.vue";
import exampleList from "@/components/DetailExampleList.vue";
import detailExamin from "@/components/detailExamin.vue";
import detailExaminWH from "@/components/detailExaminWH.vue";
import detailVideo from "@/components/detailVideo.vue"
import detailVideoWH from "@/components/detailVideoWH.vue"

import rightPlay from '@/assets/images/rightPlay.png' 
import rightPlay2 from '@/assets/images/rightPlay2.png'
export default {
  components: { certSave, VueQr, player, lessonList, exampleList, detailExamin,detailExaminWH, detailVideo,detailVideoWH },
  name: "detail",
  
  data() {
    return {
      rightPlay,
      rightPlay2,
      rightIndex: 1,
      rightTab:[{
        name:'课程目录',
        id: 1
      },{
        name:'课程讲义',
        id: 2
      }],
      isPassAll: true,
      pass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.jpg",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.jpg",

      currentInfo:{},

      typeList: [],
      allTypeList: [
        {
          name: "课程介绍",
          id: 1,
        },
        {
          name: "课程列表",
          id: 2,
        },
        {
          name: "课程考试",
          id: 6,
        },
        {
          name: "培训证书",
          id: 8,
        },
      ],
      baseInfo: {},
      lessonNum: 0,
      lessonList: [], //课程列表
      down_list:[],//实操课程
      map: [], //思维导图
      handouts: [], //讲义
      demonstrate: [], //示范课程
      paper_book: [], //纸质书籍
      books: [], //推荐书籍
      payUrl: "",
      orderShow: false,
      dialogVisible: false,
      pageLoading: true,
      buyTimer: null,
      buyTime: 300,
      menu: 2,
      id: 0,
      lesson: "",
      list: [],
      activeTab: 1,
      textContent: "",
      color: ["#666", "#FF4D41", "#6DD400"],
      payTimer: null,
      isPay: false,
      examinList: [], //理论课程考试
      examinListCe:[],
      examinListShiCe:[],
      examinListShi: [],//实操课程考试
      testInfo: {},
      uploading: null
    };
  },
  mounted() {

    this.$router.onReady(() => {
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.activeTab = 2
        this.getData();
      }
    });
  },
  methods: {
    getTag(id) {
      this.isPassAll = true
      this.activeTab = id;
    },
 
    jumpBook(item) {
      window.open(item.url);
    },
    toExamin(item) {
      if (!this.baseInfo.can_exam) {
        this.$toast("您的课程还没有全部学习完成，请学习完全部课程后在参加考试");
        return;
      }
      if (this.baseInfo.can_exam) {
        this.$router.push({
          path: "./examinList",
          query: {
            id: item.id,
            isExamin: true,
          },
        });
      } else {
        this.$toast("您还未满足考试要求");
      }
    },
    closeBuy() {},
    getCert(){
      //  this.pageLoading = true
       this.pageLoading = false
    }, 
    async getData(type) {
      
      if (!this.isPay && !type) this.pageLoading = true;
      let res = await this.$request.tuoyuDetail({ discipline_id: this.id });

      this.pageLoading = false;
      this.lessonList = []
      this.demonstrate =[]
      this.down_list = []
      if (res.code == 0) {
        this.lessonList = res.data.list; //课程列表
        if(this.lessonList.length == 0){
          this.activeTab = 1
        }
        this.down_list = res.data.down_list || []
        let lesson = this.flatArr(this.lessonList,[])
        this.lessonNum = lesson.length;
        this.currentInfo = lesson[0] || {}

        this.map = res.data.map; //思维导图
        this.handouts = res.data.handouts; //讲义
        this.demonstrate = res.data.demonstrate; //示范课程
        this.paper_book = res.data.paper_book; //纸质书籍
        this.baseInfo = res.data.base; //课程介绍
        // this.baseInfo.is_buy = res.data.is_buy;
        // this.baseInfo.can_exam = res.data.can_exam;
        // this.baseInfo.exam_pass = res.data.exam_pass;
        // // this.baseInfo.test_message = res.data.test_message;
        // this.baseInfo.test_id = res.data.base.test_id;
        this.baseInfo.is_cert = res.data.is_cert;
        // this.baseInfo.discipline_id = res.data.base.id;
        // this.baseInfo.test_message = res.data.test_message
        if(this.baseInfo.is_cert){
          this.$nextTick(()=>{
            this.$refs.cert.getData(res.data.base.id)
          })
        }
        
        if(type){
          this.uploading.close();
          this.$toast('上传成功');
        } 
        
        let examRes = await this.$request.detailExaminList(res.data.base.id);
        if (examRes.code == 0) {
            this.examinListCe = examRes.data && examRes.data.ce? [examRes.data.ce] : [];
            this.examinList = examRes.data && examRes.data.zheng? [examRes.data.zheng] : [];
          } else {
            this.$toast(examRes.msg);
          }
        
        console.log(this.examinList)
        let typeShow = [
          true,
          this.lessonList.length > 0 || this.down_list.length > 0,
          this.examinList.length || this.examinListCe.length || this.examinListShiCe.length || this.examinListShi.length,
          res.data.is_cert,
        ];
        if(this.id=='588'){
          this.allTypeList[0].name="培训简介"
        }
        this.typeList = this.allTypeList.filter((item, index) => {
          return typeShow[index];
        });
        // 1 为课程  2为培训

        // this.activeTab = this.ifVIPDetail ? 0 : 1
        // console.log(this.typeList)
        if (this.isPay) {
          if (!res.data.is_buy) {
            this.payTimer = setTimeout(() => {
              this.getData();
            }, 1000);
          } else {
            this.isPay = false;
            this.dialogVisible = false;
          }
        }
      } else {
        this.$toast(res.msg);
      }

      // let res2 = await this.$request.tuoyuDetailBooks({
      //   goods_category_id: this.baseInfo.category_id,
      // });
      // if (res2.code == 0) {
      //   this.books = res2.data;
      // } else {
      //   this.$toast(res2.msg);
      // }

    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          // console.log("lesson");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "chapter" },
          ];
          // console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          // console.log("speak");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          // console.log("none");
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },

    toPlay(type, item) {
      if(!localStorage.getItem('user')){
        this.$store.commit('changeLoginShow',true)
        return
      }
       window.sessionStorage.setItem(this.$route.query.id, true)
      this.toPlay2(item)
    },
    toPlay2(info){
      this.currentInfo = info
    },
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    toPage(url) {
      this.$router.push(url);
    },
  },
  computed: {
    vipInfo() {
      return this.$store.state.vipInfo || {};
    },
    partalName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    userName() {
      return localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).nickname : null;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  watch:{
    isLogin(){
      if(this.isLogin){
        if (this.$route.query.id) {
          this.id = this.$route.query.id;
          this.activeTab = this.$route.query.activeTab || 1;
          this.getData();
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate");
    // console.log(to);
    this.id = to.query.id;
    if(this.id=='588'){
        this.allTypeList[0].name="培训简介"
      }else{
        this.allTypeList[0].name="课程介绍"
      }
    this.activeTab = this.$route.query.type == "video" ? 2 : 1;
    this.getData();
    next();
  },
  beforeDestroy() {
    clearInterval(this.payTimer);
  },
};
</script>
<style lang="scss">
body {
  background: #fff;
}
.videoUpload{
  .el-loading-spinner i{
    font-size: 28px;
  }
  .el-loading-spinner .el-loading-text{
    font-size: 20px;
  }
}
.prism-player .prism-big-play-btn{
  transform: translate(-50%, 50%);
}
</style>
<style lang="scss" scoped>

.position{
  margin-bottom: 30px;
}
.examinTitle{
  font-size: 18px;
  padding-bottom: 8px;
  padding-left: 10px;
  font-weight: bold;
  color: #222222;
  padding-top: 20px;
}
.certGet{
  margin: 20px auto 20px;
  cursor: pointer;
  width: 308px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  background: #0080d6;
  border: 0;
  display: block;
}
.passAll {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    position: relative;
    .close {
      position: absolute;
      color: #fff;
      right: 0px;
      top: -8px;
      font-size: 30px;
      cursor: pointer;
    }
  }
}
:deep .el-dialog {
  margin-top: 0 !important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
:deep .orderDialog {
  overflow: hidden;
  border-radius: 20px;
  .el-dialog__header {
    padding: 0 20px;
    height: 54px;
    display: flex;
    align-items: center;

    .el-dialog__title {
      font-size: 22px;
      color: #333333;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
.coverImg {
  img {
    display: block;
    max-width: 100%;
    width: auto;
    margin: 0 auto;
  }
  .btn {
    margin: 10px auto 20px;
    cursor: pointer;
    width: 208px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    background: #0080d6;
  }
}


.opacity {
  opacity: 0.5;
}
  
.cert {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 50px;
}

.content {
  display: flex;
  justify-content: space-between;
  .left {
    flex: 0 0 800px;
    .lessonTitle{
      font-size: 24px;
      color: #333;
      margin: 30px 0 40px;
    }
    .type {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid #dddddd;
      .type-item {
        margin-right: 10px;
        color: #7f7f7f;
        font-size: 20px;
        padding-bottom: 10px;
        margin-right: 40px;
        cursor: pointer;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: none;
        color: #333;
        font-weight: bold;
        border-bottom: 4px solid #0080d6;
      }
    }
    .detailContent {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
    }

    .html {
      margin-top: 20px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: column;

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }
  }
  .right {
    flex: 1;
    height: 450px;
    background: #0e2b63;
    display: flex;
    flex-direction: column;
    .rightTab{
      display: flex;
      border-bottom: 1px solid #082253;
      padding-left: 29px;
      height: 50px;
      line-height: 50px;
      p{
        cursor: pointer;
        margin-right: 39px;
        position: relative;
        color: #dce8ff;
      }
      p.active{
        background: transparent;
      }
      p.active::after{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 24px;
        height: 4px;
        background-color: #fdb855;
        border-radius: 1.5px;
      }
    }
    .rightContent{
      flex: 1;
      padding: 30px;
      overflow-y: auto;
      color: #adbcd8;
      font-size: 16px;
      .rightLesson{
        line-height: 30px;
        padding-left: 3px;
        padding-right: 17px;
        padding-top: 10px;
        div:last-of-type{
          margin-bottom: 10px;
        }
        div{
          padding-left: 20px;
        }
        .rightName,.rightName2, .rightName3{
          cursor: pointer;
          padding: 5px 0 ;
          position: relative;
          z-index: 5;
          img{
            position: absolute;
            left: -20px;
            top: 13px;
          }
          
        }
        div.rightCurrent{
          &::after{
            content: '';
            display: block;
            width: 351px;
            height: 47px;
            border-radius: 8px;
            background-color: #2e66d0;
            color: #fff;
            position: absolute;
            top: -5px;
            z-index: -1;
          }
        }
      }
      .rightName2,.rightName3{
        font-size: 14px;
      }
      .rightName2.rightCurrent::after{
        left: -29px;
      }
      .rightName3.rightCurrent::after{
        left: -49px;
      }
    }
  }
}
</style>

<style lang="scss">
.detailRight{
  ::-webkit-scrollbar{
    width:8px;
    height:7.6px;
    border-radius:4px;
    background-color:transparent;
    /*border-bottom: 1px solid #ECECEC;*/
    /*border-top: 1px solid #ECECEC;*/
  }
  ::-webkit-scrollbar-thumb{
    background-color:#546a95;
    border-radius:4px;
    margin:5px 0;
  }
}
</style>
